
import { React } from "react";
import "./NotFound.scss";

const NotFound = () => {

  document.querySelectorAll('.notFoundContainer').forEach(item => {
    item.style.fontFamily = 'paragraphFont';
  })

  return (
    <div className="notFoundContainer">
      <div className="container-fluid h-100">
        <div className="col-12 h-100 d-flex justify-content-center align-items-center">
          De pagina die u zoekt kon niet worden gevonden.
        </div>

      </div>
    </div>
  )
}

export default NotFound;

