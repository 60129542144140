import "./Slider.scss";
import { React, useEffect } from "react";
import SliderBlock from "../SliderBlock/SliderBlock";
// Core modules imports are same as usual
import { Navigation, Pagination } from 'swiper';
// Direct React component imports
import { Swiper, SwiperSlide } from 'swiper/react/swiper-react.js';
// Styles must use direct files imports
import 'swiper/swiper.scss'; // core Swiper
import 'swiper/modules/navigation/navigation.scss'; // Navigation module
import 'swiper/modules/pagination/pagination.scss'; // Pagination module


const Slider = (props) => {
  const vendors = props.vendors;
  const vendorArray = [];
  const textColor = props.styling.mainTextColor;


  document.querySelectorAll('.slider__title').forEach(item => {
    item.style.fontFamily = 'headTitleFont';
  })


  if (vendors.length > 0) {
    vendors.forEach((element, i) => {
      vendorArray.push(<SwiperSlide key={element.fields.name + i} className="swiper__slide d-flex justify-content-center"><SliderBlock logo={element.fields.logo.fields.file.url} url={element.fields.url} styling={props.styling} /></SwiperSlide>);
    });
  }

  useEffect(() => {
    if (!props.mobile && vendorArray.length > 3) {
      document.querySelectorAll('.swiper-button-next, .swiper-button-prev').forEach(item => {
        item.addEventListener('mouseenter', () => { item.style.opacity = 1 })
        item.addEventListener('mouseleave', () => { item.style.opacity = 0.2 })
        item.style.color = textColor;
        item.style.opacity = 0.2;
      })
    } else if (props.mobile && vendorArray.length > 1) {
      const bullets = document.getElementsByClassName('swiper-pagination-bullet');
      for (var i = 0; i < bullets.length; i++) {
        bullets[i].style.background = textColor;
      }
    }
  })


  const sliderProperties = () => {
    let sliderSettings = {
      navigation: false,
      loop: false,
      centeredSlides: true,
      allowSlide: false,
      centerInsufficientSlides: false,
      modules: [Pagination],
      slidesPerView: 2
    };
    if (props.mobile && vendorArray.length > 1) {
      sliderSettings.loop = true
      sliderSettings.allowSlide = true
    } else if (!props.mobile) {
      sliderSettings.modules = [Navigation];
      sliderSettings.slidesPerView = 3;
      if (vendorArray.length === 1) {
        sliderSettings.centeredSlides = true
      } else if (vendorArray.length === 2 || vendorArray.length === 3) {
        sliderSettings.centeredSlides = false
        sliderSettings.centerInsufficientSlides = true;
      } else if (vendorArray.length > 3) {
        sliderSettings.loop = true;
        sliderSettings.navigation = true;
        sliderSettings.allowSlide = true;
      }
    }
    return sliderSettings;
  }


  return (
    <div className="container g-0 mb-4">
      <div className="slider">
        <div className="row">
          <div className="col-12 d-flex justify-content-center p-2">
            <div className="slider__title" style={{ color: textColor }}>
              {props.title}
            </div>
          </div>
        </div>
        <div className="col-12 w-100">
          <Swiper
            modules={sliderProperties().modules}
            slidesPerView={sliderProperties().slidesPerView}
            allowSlideNext={sliderProperties().allowSlide}
            allowSlidePrev={sliderProperties().allowSlide}
            allowTouchMove={sliderProperties().allowSlide}
            loop={sliderProperties().loop}
            navigation={sliderProperties().navigation}
            centeredSlides={sliderProperties().centeredSlides}
            centerInsufficientSlides={sliderProperties().centerInsufficientSlides}
            spaceBetween={0}
            breakpoints={{
              // when window width is >= 768px
              768: {
                spaceBetween: -50
              }
            }}
            slidesPerGroup={1}
            pagination={{
              clickable: true,
            }}
            className="swiper">
            {vendorArray}
          </Swiper>
        </div>
      </div>
    </div >
  )
}

export default Slider;
