import "./Header.scss";
import React from "react";

const Header = (props) => {

  const logo = props.styling.logoHeader.fields.file.url;
  const logoSize = props.styling.logoHeaderSize ? props.styling.logoHeaderSize + 'px' : '60px'
  const logoPaddingTop = props.styling.logoHeaderPaddingTop ? props.styling.logoHeaderPaddingTop + 'px' : '15px';
  const logoPaddingBottom = props.styling.logoHeaderPaddingBottom ? props.styling.logoHeaderPaddingBottom + 'px' : '15px';
  const headerBackgroundColor = props.styling.headerBackgroundColor;
  const logoUrl = props.styling.brandHomepage;

  return (
    <div className="header" style={{ backgroundColor: headerBackgroundColor }}>
      <div className="container" >
        <a href={logoUrl}>
          <img className="header__logo" src={logo} alt="brandlogo" style={{ height: logoSize, paddingTop: logoPaddingTop, paddingBottom: logoPaddingBottom }} />
        </a>

      </div>
    </div>
  )
}

export default Header;

