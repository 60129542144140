import "./Hero.scss";
import React from "react";

const Hero = (props) => {
  const heroText = props.campaign.heroText;
  const heroImage = props.campaign.heroImage ? props.campaign.heroImage[0].fields.file.url : null;
  const heroTextColor = props.styling.heroTextColor;

  document.querySelectorAll('.hero__copy').forEach(item => {
    item.style.fontFamily = 'headTitleFont';
  })

  return (
    <div className="hero">
      <div className="container h-100 g-0">
        <div className="row h-100 align-items-center d-flex justify-content-center">
          {heroText && (<div className="col-12 d-flex justify-content-center order-last order-xl-first text-center text-xl-start p-3 p-xl-0"><div className="hero__copy" style={{ color: heroTextColor }}>{heroText}</div> </div>)}
          {heroImage !== null && (<div className="col-12 d-flex justify-content-center order-first order-xl-last"><img src={heroImage} className="hero__image" alt=""></img></div>)}
        </div>
      </div>
    </div>

  )
}

export default Hero;

