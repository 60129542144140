import "./Footer.scss";
import { React } from "react";

import Navlink from '../Navlink/Navlink'
import SocialIcon from "../SocialIcon/SocialIcon";
import { faFacebookF, faInstagram, faLinkedin, faTwitter, faYoutube } from "@fortawesome/free-brands-svg-icons";

const Footer = (props) => {
  const date = new Date();

  const logo = props.styling.logoFooter.fields.file.url;
  const logoSize = props.styling.logoFooterSize ? props.styling.logoFooterSize + 'px' : '30px';
  const logoUrl = props.styling.brandHomepage;

  const bgColor = props.styling.footerBackgroundColor;
  const textColor = props.styling.footerTextColor;

  const quoteLineOne = props.styling.footerQuoteLine1Color;
  const quoteLineTwo = props.styling.footerQuoteLine2Color;

  const showFooter = props.campaign.showFooterQuote;

  const logoClass = showFooter ? "col-4 d-flex justify-content-end" : "col-12 pt-3 d-flex justify-content-center"


  document.querySelectorAll('.footer__top__copy').forEach(item => {
    item.style.fontFamily = 'headTitleFont';
  })

  document.querySelectorAll('.footer__middle__linkContainer--cookieButton').forEach(item => {
    item.style.fontFamily = 'titleFont';
  })

  document.querySelectorAll('.footer__bottom__copyright').forEach(item => {
    item.style.fontFamily = 'paragraphFont';
  })


  return (
    <div className="footer" style={{ backgroundColor: bgColor }}>
      <div className="container">
        <div className="d-none d-xl-block">
          <div className="footer__top">
            <div className="row align-items-center h-100">
              {showFooter && (
                <div className="col-8">
                  <div className="row align-items-center">
                    <div className="col-2">
                      <div className="footer__top__icon" />
                    </div>
                    <div className="col-10">
                      <span className="footer__top__copy" style={{ color: quoteLineOne }}>De wereld die we morgen willen</span><br /><span className="footer__top__copy" style={{ color: quoteLineTwo }}>begint met hoe we vandaag zakendoen</span>
                    </div>
                  </div>
                </div>
              )}
              <div className={logoClass} >
                <a href={logoUrl}>
                  <img className="footer__top__logo" src={logo} alt="brandlogo" style={{ height: logoSize }} />
                </a>
              </div>
            </div>
          </div>
        </div>

        <div className="footer__middle">
          <div className="row g-0 h-100 align-items-center">
            <div className="col-12 col-xl-8 justify-content-xl-start ">
              <div className="footer__middle__linkContainer">
                <div className="row g-0 h-100 d-flex align-items-center">
                  <Navlink className="footer__middle__linkContainer--bold" url={"https://nld.mars.com/contact"} copy="Contact" styling={props.styling} />
                  <Navlink className="footer__middle__linkContainer--bold" url={"https://jobs.mars.com/?locale=nl_NL"} copy="Carrière" styling={props.styling} />
                  <div className="col-12 col-xl-auto mb-3 my-xl-0 me-5 align-items-center justify-content-xl-start">
                    <a className="footer__middle__linkContainer--cookieButton" href='#cookie-settings'>Cookie Settings</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="row g-0  col-12 col-xl-4 align-items-center d-flex justify-content-center justify-content-xl-end">
              <div className="col-12 col-xl-auto text-center text-xl-start pb-2 pb-xl-0"><div className="footer__middle__socialCopy" style={{ color: textColor }}>Volg ons: </div></div>
              <SocialIcon href={'https://www.facebook.com/Marsnederland'} icon={faFacebookF} styling={props.styling} />
              <SocialIcon href={'https://twitter.com/Marsglobal'} icon={faTwitter} styling={props.styling} />
              <SocialIcon href={'https://www.instagram.com/mars.nederland/'} icon={faInstagram} styling={props.styling} />
              <SocialIcon href={'https://www.linkedin.com/company/mars'} icon={faLinkedin} styling={props.styling} />
              <SocialIcon href={'https://www.youtube.com/user/Mars'} icon={faYoutube} styling={props.styling} />
            </div >
            <div className="col-12 d-block mt-4  d-xl-none ">
              <div className="footer__middle__divider"></div>
            </div>
          </div >
        </div >

        <div className="footer__bottom">
          <div className="row">
            <div className="col-6 col-xl-12">
              <div className="footer__bottom__linkContainer" >
                <div className="row">
                  <Navlink url={"https://www.mars.com/privacy"} target="_blank" copy="Privacyverklaring" styling={props.styling} />
                  <Navlink url={"https://www.mars.com/cookies-netherlands"} target="_blank" copy="Cookiemelding" styling={props.styling} />
                  <Navlink url={"https://www.mars.com/legal-netherlands"} target="_blank" copy="Juridische" styling={props.styling} />
                  <Navlink url={"https://nld.mars.com/sitemap"} target="_blank" copy="Sitemap" styling={props.styling} />
                  <Navlink url={"https://www.martiansreunited.com/"} target="_blank" copy="Mars-alumni" styling={props.styling} />
                </div>
              </div>
            </div>
            <div className="col-6 col-xl-12">
              <div className="footer__bottom__linkContainer">
                <div className="row">
                  <Navlink url={"https://www.mars.com/legal/ca-supply-chain-act"} target="_blank" copy="Californische wet op transparantie in de toeleveringsketen" styling={props.styling} />
                  <Navlink url={"https://www.mars.com/about/policies-and-practices/modern-slavery-act"} target="_blank" copy="Wet Moderne slavernij" styling={props.styling} />
                  <Navlink url={"https://nld.mars.com/node/5501"} target="_blank" copy="Toegankelijkheid " styling={props.styling} />
                  <Navlink url={"https://www.mars.com/mars-incorporated-adchoices-united-states"} target="_blank" copy="Advertentiekeuzes" styling={props.styling} />
                </div>
              </div>
            </div>
            <div>
              <div className="footer__bottom__copyright" style={{ color: textColor }} >
                <div className="col-12  text-center text-xl-start">
                  © {date.getFullYear()} Mars, Incorporated and its Affiliates. All Rights Reserved
                </div>
              </div>
            </div>
          </div>
        </div>
      </div >
    </div >
  )
}

export default Footer;
