import "./SliderBlock.scss";
import React from "react";
import { useState } from "react"

const SliderBlock = (props) => {
  const vendorLogo = props.logo;

  const ctaBgColor = props.styling.ctaBackgroundColor;
  const defaultCtaTextColor = props.styling.ctaTextColor;

  const ctaHoverBgColor = props.styling.ctaBackgroundHoverColor;
  const ctaHoverTextColor = props.styling.ctaTextHoverColor;

  const [ctaBackgroundColor, setCtaBackgroundColor] = useState(ctaBgColor);
  const [ctaTextColor, setCtaTextColor] = useState(defaultCtaTextColor);

  document.querySelectorAll('.sliderblock__cta').forEach(item => {
    item.style.fontFamily = 'ctaFont';
  })

  return (
    <a href={props.url} target="_blank" rel="noreferrer" onMouseLeave={() => { setCtaBackgroundColor(ctaBgColor); setCtaTextColor(defaultCtaTextColor) }} onMouseEnter={() => { setCtaBackgroundColor(ctaHoverBgColor); setCtaTextColor(ctaHoverTextColor) }}>
      < div className="sliderblock" >
        <div className="row h-50 mt-2 mt-xl-4">
          <div className="col-12 d-flex justify-content-center align-items-center">
            <div className="sliderblock__logo">
              {vendorLogo && (<img src={vendorLogo} alt=""></img>)}
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-12 d-flex justify-content-center">
            <div className="sliderblock__cta" style={{ backgroundColor: ctaBackgroundColor, color: ctaTextColor }}>
              Snel winkelen
            </div>
          </div>
        </div>
      </div >
    </a >

  )
}

export default SliderBlock;
