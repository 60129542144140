
import { React, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const SocialIcon = (props) => {

  const iconColor = props.styling.footerIconColor;

  const defaultIconBackgroundColor = props.styling.footerIconBackgroundColor;
  const hoverIconBackgroundColor = props.styling.footerIconBackgroundHoverColor;

  const [iconBackgroundcolor, setIconBackgroundColor] = useState(defaultIconBackgroundColor);

  return (
    <a href={props.href} target="_blank" className="footer__middle__socialContainer" style={{ background: iconBackgroundcolor }} onMouseEnter={() => { setIconBackgroundColor(hoverIconBackgroundColor) }} onMouseLeave={() => { setIconBackgroundColor(defaultIconBackgroundColor) }} rel="noreferrer"><div><FontAwesomeIcon className="footer__middle__socialContainer__socialIcon" icon={props.icon} style={{ color: iconColor }} /></div></a>
  )
}

export default SocialIcon;

